import { createSlice, configureStore } from "@reduxjs/toolkit";
import dummyRiskLogSlice from "./dummyRiskLogConfiguration";
import rootCauseSVDSlice from "./rootCauseSVD";
import gulerMakSlice from "./gulerMak";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: { sidebarOpen: false },
  reducers: {
    toggle(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
  },
});

const modalSlice = createSlice({
  name: "modal",
  initialState: { modalVisible: false, scenarioRequest: false, modalType: "" },
  reducers: {
    toggle(state, action) {
      state.modalVisible = !state.modalVisible;
      state.modalType = action.payload;
    },
    getScenario(state) {
      state.scenarioRequest = !state.scenarioRequest;
    },
  },
});

// const addMemberModalSlice = createSlice({
//     name: 'addMemberModalSlice',
//     initialState: {modalVisible: false},
//     reducers: {
//         toggle(state) {
//             state.modalVisible = !state.modalVisible
//         },
//     }
// });

const scenarioSlice = createSlice({
  name: "baselineNscenario",
  initialState: {
    baselineTasks: null,
    scenarioTasks: null,
    ganttChartTasks: null,
  },
  reducers: {
    baselineTasksTable(state, action) {
      state.baselineTasks = action.payload;
    },
    scenarioTasksTable(state, action) {
      state.scenarioTasks = action.payload;
    },
    scenarioTasksGanttChart(state, action) {
      state.ganttChartTasks = action.payload;
    },
  },
});

const timeManagerSlice = createSlice({
  name: "timeManager",
  initialState: {
    activeFeature: "Core",
    internalActiveFea: "",
    singleScerioLoaderValue: 0,
    openPrognosis: false,
    displayGanttChart: false
  }, // Predictions // Oculus DV // Core // Pattern Recognition // Diagnostics // Scenario Modeling
  reducers: {
    changeFeature(state, action) {
      state.activeFeature = action.payload;
    },
    changeInternalFeature(state, action) {
      state.internalActiveFea = action.payload;
    },
    changeFeaturetoPrognosis(state, action) {
      state.activeFeature = action.payload;
      state.openPrognosis = true;
    },
    automationToGanttChart(state, action) {
      console.log("Gant chart becomes true", action.payload)
      state.displayGanttChart = action.payload;
    },
  },
});
const resourceOptimizerSlice = createSlice({
  name: "ResourceOptimizer",
  initialState: {
    activeFeature: "+ Resources",
    internalActiveFea: "",
    singleScerioLoaderValue: 0,
  },
  reducers: {
    changeFeature(state, action) {
      state.activeFeature = action.payload;
    },
    changeInternalFeature(state, action) {
      state.internalActiveFea = action.payload;
    },
  },
});
const VivclimaSlice = createSlice({
  name: "vivclima",
  initialState: { activeFeature: "Core", internalActiveFea: "" }, // Simulation
  reducers: {
    changeFeature(state, action) {
      state.activeFeature = action.payload;
    },
    changeInternalFeature(state, action) {
      state.internalActiveFea = action.payload;
    },
  },
});

const CostSlice = createSlice({
  name: "cost",
  initialState: { activeFeature: "Core", internalActiveFea: "" },
  reducers: {
    changeFeature(state, action) {
      state.activeFeature = action.payload;
    },
    changeInternalFeature(state, action) {
      state.internalActiveFea = action.payload;
    },
  },
});

const ProjectManagerSlice = createSlice({
  name: "ProjectManager",
  initialState: {
    activeFeature: "Recent projects",
    internalActiveFea: "",
    activeProject: "",
    filesViewOpen: false,
  },
  reducers: {
    changeFeature(state, action) {
      state.activeFeature = action.payload;
    },
    changeInternalFeature(state, action) {
      state.internalActiveFea = action.payload;
      // console.log("reducer", action.payload);
    },
    changeactiveProject(state, action) {
      state.activeProject = action.payload;
    },
    changeFileView(state, action) {
      state.filesViewOpen = action.payload;
    },
  },
});

const VAISlice = createSlice({
  name: "VisionAI",
  initialState: {
    activeFeature: "Core",
    internalActiveFea: "",
    activeProject: "",
    openPrognosis: false,
  }, // Core, Predictions
  reducers: {
    changeFeature(state, action) {
      state.activeFeature = action.payload;
    },
    changeInternalFeature(state, action) {
      state.internalActiveFea = action.payload;
    },
    changeactiveProject(state, action) {
      state.activeProject = action.payload;
    },
    changeFeaturetoPrognosis(state, action) {
      state.activeFeature = action.payload;
      state.openPrognosis = true;
    },
  },
});

const estimationAppSlice = createSlice({
  name: "estimationApp",
  initialState: {
    activeFeature: "Core",
    internalActiveFea: "",
    singleScerioLoaderValue: 0,
  }, // Build your Bid
  reducers: {
    changeFeature(state, action) {
      state.activeFeature = action.payload;
    },
    changeInternalFeature(state, action) {
      state.internalActiveFea = action.payload;
    },
  },
});

// const exposurePathToMonitorNControl = createSlice({
//   name: 'variable',
//   initialState: { value: false },
//   reducers: {
//     setvariableTrue(state, action) {
//       state.value = action.payload
//     }
//   },
// });

const riskManagementSlice = createSlice({
  name: "riskManagement",
  initialState: {
    activeFeature: "Core",
    internalActiveFea: "",
    singleScerioLoaderValue: 0,
    displayMonitornControl: false
  }, // Core // Oculus DV // Predictions // RM Forms
  reducers: {
    changeFeature(state, action) {
      state.activeFeature = action.payload;
    },
    changeInternalFeature(state, action) {
      state.internalActiveFea = action.payload;
    },
    exposurePathToMonitorNControl(state, action) {
      state.displayMonitornControl = action.payload
    }
  },
});

const rightSidebarPanel = createSlice({
  name: "rightSidebarPanel",
  initialState: { open: false, transfer: false },
  reducers: {
    toggle(state) {
      state.open = !state.open;
      state.transfer = false;
    },
    toggleTransfer(state) {
      state.open = !state.open;
      state.transfer = true;
    },
  },
});

const errorInfoHandler = createSlice({
  name: "errorInfoHandler",
  initialState: { error: false },
  reducers: {
    showError(state, action) {
      state.error = action.payload;
    },
  },
});

const generalLoaderSlice = createSlice({
  name: "generalLoaderSlice",
  initialState: {
    loaderVisible: false,
    progress: 0,
    numberOfRequests: 0,
    error: false,
  },
  reducers: {
    showLoader(state, action) {
      state.loaderVisible = action.payload;
    },
    loaderValue(state, action) {
      action.payload !== 0
        ? (state.progress = state.progress + action.payload)
        : (state.progress = action.payload);
    },
    requestsCount(state, action) {
      state.numberOfRequests = action.payload;
    },
    showError(state, action) {
      state.error = action.payload;
    },
  },
});

const vASlice = createSlice({
  name: "vASlice",
  initialState: { visible: false },
  reducers: {
    toggle(state) {
      state.visible = !state.visible;
    },
  },
});

const chatSlice = createSlice({
  name: "chatSlice",
  initialState: { visible: false, person: null },
  reducers: {
    toggle(state) {
      state.visible = !state.visible;
    },
    openChat(state, action) {
      state.visible = !state.visible;
      state.person = action.payload;
    },
  },
});

const commentingSlice = createSlice({
  name: "commentingSlice",
  initialState: { visible: false, pageType: '', allComments: [], pageComment: [] },
  reducers: {
    toggle(state) {
      state.visible = !state.visible;
    },
    updatePageType(state, action) {
      const newPageType = action.payload
      state.pageType = newPageType;
      state.pageComment = state.allComments.filter((x) => x.currentPage === newPageType)
    },
    updatePageComment(state, action) {
      const annotation = action.payload;
      const { allComments, pageType } = state;
      let allCommentsClone = [...allComments];
      const existingIndex = state.allComments.findIndex(
        (a) => a.id === annotation.id
      );
      if (existingIndex !== -1) {
        allCommentsClone[existingIndex] = annotation;
      } else {
        allCommentsClone.push(annotation)
      }

      state.allComments = allCommentsClone
      state.pageComment = allCommentsClone.filter((x) => x.currentPage === pageType)
    },
  },
});

export const sidebarActions = sidebarSlice.actions;
export const modalActions = modalSlice.actions;
// export const addMemberModalActions =  addMemberModalSlice.actions;
export const scenarioTasksActions = scenarioSlice.actions;
export const timeManagerActions = timeManagerSlice.actions;
export const rightSidebarActions = rightSidebarPanel.actions;
export const vivclimaActions = VivclimaSlice.actions;
export const costActions = CostSlice.actions;
export const VisionAIActions = VAISlice.actions;
export const projectManagerActions = ProjectManagerSlice.actions;
export const errorInfoHandlerActions = errorInfoHandler.actions;
export const generalLoaderActions = generalLoaderSlice.actions;
export const vAActions = vASlice.actions;
export const chatActions = chatSlice.actions;
export const commentingActions = commentingSlice.actions;
export const estimationAppActions = estimationAppSlice.actions;
export const ResourceOptimizerActions = resourceOptimizerSlice.actions;
export const riskManagementActions = riskManagementSlice.actions;
export const dummyRiskLogActions = dummyRiskLogSlice.actions;
export const rootCauseSVDActions = rootCauseSVDSlice.actions;
export const gulerMakActions = gulerMakSlice.actions;

const store = configureStore({
  reducer: {
    sidebar: sidebarSlice.reducer,
    modal: modalSlice.reducer,
    baselineNscenario: scenarioSlice.reducer,
    timeManager: timeManagerSlice.reducer,
    vivclima: VivclimaSlice.reducer,
    cost: CostSlice.reducer,
    VisionAI: VAISlice.reducer,
    rightSidebarPanel: rightSidebarPanel.reducer,
    ProjectManager: ProjectManagerSlice.reducer,
    errorInfoHandler: errorInfoHandler.reducer,
    generalLoader: generalLoaderSlice.reducer,
    virtualAssistant: vASlice.reducer,
    chat: chatSlice.reducer,
    commenting: commentingSlice.reducer,
    estimationApp: estimationAppSlice.reducer,
    ResourceOptimizer: resourceOptimizerSlice.reducer,
    riskManagement: riskManagementSlice.reducer,
    dummyRiskLog: dummyRiskLogSlice.reducer,
    rootCauseSVD: rootCauseSVDSlice.reducer,
    gulerMak: gulerMakSlice.reducer,
  },
});

export default store;
